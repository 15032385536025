import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { myFetch } from "../helpers/my_fetch";
import { Flex, Button, Modal, Col, Row } from "antd";
import CreateCar from "../components/Forms/Create";
import TheCard from "../components/Card";
import Loader from "../components/Loader";
//@ts-ignore
import { PlusCircleFilled } from "@ant-design/icons";
import { showErrorNotify } from "../helpers/notify";
import ModeControls from "../components/Controls";
import { IState } from "../types/store";
import { IRes } from "../types/index";
import MyTable from "../components/MyTable";
/*
 * Страница списка объектов
 */
export default function (): ReactElement {
  const mode = useSelector((state: IState) => state.view);

  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now());
  // Запрос данных с сервера
  const getData = useCallback(async () => {
    try {
      const { data, error }: IRes = await myFetch("", {}, "GET", 1);

      if (error) return showErrorNotify(error);

      setList(data);
    } catch ({ message }: any) {
      // console.log(message);
    } finally {
      setLoader(false);
    }
  }, []);
  // Определяем вид списка карточек
  const isGrid = useMemo(() => mode === "grid", [mode]);
  // Действия при изменении getData
  useEffect(() => {
    getData();
  }, [getData]);
  // Функция закрытия модального окна
  const toClose = useCallback(async () => {
    setIsModal(false);
    setModalKey(Date.now());
  }, []);
  // Объект навигации
  const navigate = useNavigate();
  // Функция перехода на внутреннею страницу объекта
  const toInsidePage = (obj: any) => {
    // Перенаправление
    navigate(`/inside/${obj?.id}`);
  };
  // Показываем loader
  if (loader) return <Loader />;
  // Возвращаем шаблон компонента
  return (
    <Flex vertical gap="small">
      <Flex justify="space-between" gap="small">
        <Button
          size="small"
          type="primary"
          onClick={() => setIsModal(true)}
          style={{ backgroundColor: "var(--green)" }}
          icon={<PlusCircleFilled />}
        />

        <ModeControls />
      </Flex>

      {!isGrid && <MyTable list={list} toInsidePage={toInsidePage} />}

      {isGrid && (
        <Row gutter={[8, 8]}>
          {list &&
            list.map((object: any) => {
              return (
                <Col
                  xs={isGrid ? 12 : 24}
                  sm={isGrid ? 8 : 24}
                  md={isGrid ? 8 : 24}
                  lg={isGrid ? 6 : 24}
                  xl={isGrid ? 4 : 24}
                  xxl={isGrid ? 2 : 24}
                  key={object?.id}
                >
                  <TheCard object={object} isGrid={isGrid} />
                </Col>
              );
            })}
        </Row>
      )}

      <Modal open={isModal} onCancel={toClose}>
        <CreateCar getData={getData} toClose={toClose} key={modalKey} />
      </Modal>
    </Flex>
  );
}
