import { getCookie } from "./cookie";
import { getLocalStorageData } from "./localstorage";
import { brands } from "../data/car_brands";
import { IBrand, IYear } from "../types";
/*
 * Список ключей, которые будут использоваться
 */
export const desiredKeys: string[] = ["id", "name"];
// Функция генерации пути для изображения
export const generateImagePath = (name: string): string => {
  // Получаем ID пользователя из локального хранилища или куки
  const userid = getLocalStorageData("id") || getCookie("id");
  // Возвращаем путь к изображению на основе ID пользователя и имени файла
  return `${process.env.REACT_APP_BASE_URL}/get/${userid}/${name}`;
};
/*
 * Функция форматирования даты
 */
export const getFormatDate = (time: number = 0): string => {
  // Минимальная временная метка для корректной работы
  const minTime = new Date(1970, 0, 2).getTime();
  // Проверяем корректность времени, если неверно — возвращаем "не известно"
  if (!time || isNaN(time) || time < 0 || time < minTime) return "не известно";
  // Опции для форматирования даты и времени
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  // Возвращаем отформатированную дату
  return new Intl.DateTimeFormat("ru-RU", options).format(time);
};
/*
 * Функция форматирования стоимости
 */
export const getFormatCost = (cost: number | string): string => {
  // Опции для форматирования стоимости
  const options = {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
  };
  const zero = "0 ₽"; // Если стоимость некорректна, возвращаем 0 ₽
  // Преобразуем строку в число
  const number = Number(String(cost).replace(/[.]/g, ""));
  // Проверяем корректность стоимости
  if (isNaN(number)) return zero;
  if (number <= 0) return zero;
  // @ts-ignore Возвращаем отформатированную стоимость
  return String(new Intl.NumberFormat("ru-RU", options).format(number));
};
/*
 * Функция форматирования пробега
 */
export const getFormatMileage = (mileage: number | string): string => {
  // Опции для форматирования чисел
  const options = {
    useGrouping: true,
  };
  const zero = "0 km"; // Возвращаем 0 km при некорректном значении
  // Преобразуем строку в число
  const number = Number(mileage);
  // Проверяем корректность пробега
  if (isNaN(number)) return zero;
  if (number <= 0) return zero;
  // Форматируем пробег и заменяем пробелы
  const result = String(
    new Intl.NumberFormat("ru-RU", options).format(number),
  ).replace(/\u00a0/g, " ");
  return `${result} km`; // Возвращаем результат с км
};
/*
 * Маскировка стоимости
 */
export const maskCost = (value: string | number): string => {
  const zero = ""; // Пустая строка, если значение некорректно
  // Если значение отсутствует, возвращаем пустую строку
  if (!value) return zero;
  // Преобразуем строку в число
  const number = Number(String(value).replace(/ /g, ""));
  // Проверяем корректность числа
  if (isNaN(number) || number <= 0) return zero;
  // Оставляем только цифры
  const onlyNumbers = String(value).replace(/\D/g, "");
  // Если цифр нет, возвращаем пустую строку
  if (!onlyNumbers) return zero;
  // Возвращаем строку с разделением тысяч
  return onlyNumbers.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
/*
 * Маскировка телефонного номера, сохраняя формат на каждом шаге
 */
export const maskPhoneNumber = (value: string): string => {
  // Очищаем номер от всех символов, кроме цифр
  const cleanedValue = value.replace(/\D+/g, "");
  // Начало маски, начинаем с "8" и открытой скобки
  let formattedValue = "8 (";
  // Если в cleanedValue нет цифр, возвращаем только "8 ("
  if (cleanedValue.length === 0) return formattedValue;
  // Если есть цифры, добавляем код города/оператора
  formattedValue += cleanedValue.substring(1, 4);
  // Если номер длиннее 3 цифр, добавляем ") "
  if (cleanedValue.length > 4) {
    formattedValue += ") " + cleanedValue.substring(4, 7);
  }
  // Если номер длиннее 6 цифр, добавляем "-XX"
  if (cleanedValue.length > 7) {
    formattedValue += "-" + cleanedValue.substring(7, 9);
  }
  // Если номер длиннее 8 цифр, добавляем "-XX"
  if (cleanedValue.length > 9) {
    formattedValue += "-" + cleanedValue.substring(9, 11);
  }
  // Если номер слишком длинный, обрезаем его до 11 цифр
  return formattedValue;
};
/*
 * Маскирует номер автомобиля в соответствии с заданным шаблоном
 */
export const maskCarNumber = (v: string): string => {
  // Если нет входной строки, возвращаем пустую строку
  if (!v) return "";
  // Шаблон маски для номера автомобиля
  const m = "A###AA###";
  let res = ""; // Результирующая строка
  let digitIdx = 0; // Индекс для цифр
  let letterIdx = 0; // Индекс для букв
  // Очищаем строку от всех символов, кроме цифр
  const cleanDigits = v.replace(/[^\d]+/g, "");
  // Очищаем строку от всех символов, кроме кириллических букв
  const cleanLetters = v.replace(/[^а-яА-ЯёЁ]+/g, "");
  // Пробегаем по шаблону
  for (let i = 0; i < m.length; i++) {
    // Если текущий символ шаблона — "#", добавляем цифру
    if (m[i] === "#") {
      if (digitIdx < cleanDigits.length) {
        res += cleanDigits[digitIdx];
        digitIdx++;
      } else {
        break;
      }
    }
    // Если текущий символ — "A", добавляем букву
    else if (m[i] === "A") {
      if (letterIdx < cleanLetters.length) {
        res += cleanLetters[letterIdx];
        letterIdx++;
      } else {
        break;
      }
    }
    // Иначе добавляем сам символ шаблона
    else {
      res += m[i];
    }
  }
  // Возвращаем замаскированный номер
  return res;
};
/*
 * Получает список брендов автомобилей
 */
export const getBrands = (test: boolean = false): IBrand[] => {
  // Если включен режим теста, возвращаем только первые 4 бренда
  if (test) {
    return brands.slice(0, 4).map((el) => {
      return {
        label: String(el.name),
        value: String(el.name),
      };
    });
  }
  // Возвращаем полный список брендов
  return brands.map((el) => {
    return {
      label: String(el.name),
      value: String(el.name),
    };
  });
};
/*
 * Получает список годов с обратным порядком
 */
export const getYears = (test: boolean = false): IYear[] => {
  const result = []; // Массив результатов
  const now = new Date(); // Текущая дата
  // Устанавливаем текущий и стартовый годы в зависимости от режима теста
  const currentYear = test ? 2024 : now.getFullYear();
  const startYear = test ? 2020 : 1950;
  // Добавляем года от начального до текущего в массив
  for (let year = startYear; year <= currentYear; year++) {
    result.push({
      label: String(year),
      value: String(year),
    });
  }
  // Возвращаем перевернутый массив
  return result.reverse();
};
/*
 * Очищает строку от HTML-тегов и приводит к нижнему регистру
 */
export const sanitize = (val: string): string => {
  return val.replace(/<[^>]*>/gi, "").toLowerCase();
};
/*
 * Функция, которая удаляет пробелы из строки или числа
 */
export const delSpaces = (row: string | number): string => {
  // Преобразуем входные данные в строку, очищаем и удаляем пробелы по краям
  return sanitize(String(row)).trim();
};
/*
 * Функция загрузки изображения
 */
export const downloadImage = async (name: string): Promise<void> => {
  // Выполняем запрос на сервер для получения изображения
  const img = await fetch(generateImagePath(name));
  // Преобразуем полученные данные в Blob (двоичный объект)
  const blob = await img.blob();
  // Создаем URL для Blob-объекта
  const url = window.URL.createObjectURL(blob);
  // Создаем временный элемент <a>, который будет использоваться для загрузки
  const link = document.createElement("a");
  // Присваиваем ссылке URL загружаемого изображения
  link.href = url;
  // Задаем имя файла для загрузки
  link.download = name;
  // Добавляем ссылку на страницу, чтобы выполнить клик
  document.body.appendChild(link);
  // Выполняем клик на созданной ссылке для начала загрузки
  link.click();
  // Удаляем ссылку со страницы
  document.body.removeChild(link);
  // Освобождаем память, удаляя временный URL
  window.URL.revokeObjectURL(url);
};
