import React, { useState, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import {
  clearLocalstorage,
  getStatusLocalStorageData,
} from "../../helpers/localstorage";
import { clearCookies, getStatusCookie } from "../../helpers/cookie";
import DownloadBtn from "../Controls/DownloadBtn";
import "./index.css";
// Компонент navbar в шапке сайта
export default function (): ReactElement {
  // Состояние авторизованности
  const [isAuth, setIsAuth] = useState(true);
  // Флаг наличия нужных данных в localstorage
  const statusLocalstorage = getStatusLocalStorageData();
  // Флаг наличия нужных данных в cookie
  const statusCookie = getStatusCookie();
  // Функция для выхода из системы
  const logout = () => {
    clearLocalstorage();
    clearCookies();
    setIsAuth(false);
  };
  // Возвращаем шаблон компонента
  return isAuth && (statusLocalstorage || statusCookie) ? (
    <nav className="navbar">
      <DownloadBtn title="скачать все данные" />

      <Button size="small" type="primary" danger onClick={logout}>
        <LogoutOutlined />
      </Button>
    </nav>
  ) : (
    <Navigate to="/auth" />
  );
}
