import React, { useState, useEffect, useCallback, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Flex, Empty } from "antd";
import Loader from "../components/Loader";
import InsidePageHeader from "../components/Header/Inside";
import TheRow from "../components/Row";
import { myFetch } from "../helpers/my_fetch";
import InsidePageBtns from "../components/Controls/Buttons";
import { showErrorNotify } from "../helpers/notify";
import { IRes } from "../types/index";
import MyDivide from "../components/MyDivide";
/*
 * Внутреняя страница объекта
 */
export default function (): ReactElement {
  // Переменная для данных
  const [car, setCar]: any = useState(null);
  // Переменная для Loader
  const [loader, setLoader] = useState(true);
  // Получаем id объекта из URL строки
  const { id } = useParams();
  // Функция запроса данных по id объекта
  const getData = useCallback(async () => {
    try {
      // Получаем данные
      const { data, error }: IRes = await myFetch(id);
      // Показываем ошибку если она пришла с сервера
      if (error) return showErrorNotify(error);
      // Добавляем данные в переменную
      setCar(data);
      // Возвращаем данные
      return data;
    } catch (err: any) {
      // console.log(err.message)
    } finally {
      // Меняем статус Loader
      setLoader(false);
    }
  }, [id]);
  // Действия при изменении getData
  useEffect(() => {
    // Запрос данных
    getData();
  }, [getData]);
  // Возвращаем Loader
  if (loader) return <Loader />;
  // Возвращаем шаблон
  return (
    <Flex vertical gap="small">
      <InsidePageBtns getData={getData} id={String(id)} isData={car} />

      <InsidePageHeader getData={getData} isData={car} id={String(id)} />

      <MyDivide title="Детали" />

      {!car?.details?.length && <Empty />}

      {car?.details &&
        car?.details
          ?.reverse()
          ?.map((detail: any, i: number) => (
            <TheRow
              getData={getData}
              id={String(id)}
              isData={detail}
              isPage="car"
              index={i}
              length={car?.details?.length}
              key={detail?.id}
            />
          ))}
    </Flex>
  );
}
