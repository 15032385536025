import React, { ReactElement } from "react";
import { Flex } from "antd";
import { getFormatDate, maskCost } from "../../helpers";
import MyDivide from "../MyDivide";
/*
 * Объект стилей
 */
const styles = {
  row: {
    fontSize: "var(--fontSizeSmall)",
    color: "var(--black)",
  },
  span: {
    fontSize: "var(--fontSizeSmall)",
    color: "var(--black)",
    paddingLeft: "var(--paddingSmall)",
  },
};
/*
 * Компонент строки
 */
function MyRow({
  title,
  content,
}: {
  title: string;
  content: string;
}): ReactElement {
  // Возвращаем шаблон компонента
  return (
    <p style={styles.row} title={`${title}: ${content}`}>
      {title}:<span style={styles.span}>{content}</span>
    </p>
  );
}
/*
 * Компонент подробного описания
 */
export default function ({ data }: { data: any }): ReactElement {
  // Возвращаем шаблон компонента
  return (
    <Flex gap="small" vertical>
      <MyRow title="Дата создания" content={getFormatDate(data.date.start)} />

      {!!data?.name && <MyRow title="Название" content={data?.name} />}

      {!!data?.description && (
        <MyRow title="Описание" content={data?.description} />
      )}

      {!!data?.sum && (
        <MyRow title="Сумма затрат" content={maskCost(data?.sum)} />
      )}

      {!!data?.brand && <MyRow title="Бренд" content={data?.brand} />}

      {!!data?.model && <MyRow title="Модель" content={data?.model} />}

      {!!data?.year && <MyRow title="Год выпуска" content={data?.year} />}

      {!!data?.number && <MyRow title="Гос. номер" content={data?.number} />}

      {!!data?.milleage && (
        <MyRow title="Пробег" content={`${maskCost(data?.milleage)} км`} />
      )}

      {!!data?.options && <MyDivide title="Детали" />}

      {!!data?.options &&
        Object.keys(data?.options).map((key, i) => {
          return (
            <MyRow
              title={data?.options[key]?.name}
              content={maskCost(data?.options[key]?.cost)}
              key={i}
            />
          );
        })}
    </Flex>
  );
}
