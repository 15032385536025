import React, { ReactElement, useState, useRef, useEffect } from "react";
import icon from "../../assets/selectIcon.png";
import { IOptionItem } from "../../types";
import MyInput from "../MyInput";
import "./index.css"; // Импорт стилей
/*
 * Интерфейс входных данных
 */
interface IProps {
  options: IOptionItem[];
  title?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}
/*
 * Кастомный компонент select
 */
export default function ({
  options = [],
  title = "",
  placeholder = "",
  onChange,
}: IProps): ReactElement {
  // Стейт для хранения выбранного значения
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");
  // Ссылка на компонент
  const selectRef = useRef<HTMLDivElement>(null);
  // Обработчик выбора элемента
  const handleSelect = (option: IOptionItem) => {
    setLabel(option.label);
    onChange(option.value); // Передаем выбранное значение через onChange
    setIsDropdownOpen(false); // Закрываем список
  };
  // Обработчик клика на инпут
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Открываем или закрываем список
  };
  // Устанавливаем первое значение из переданного массива
  useEffect(() => {
    const data = options[0];
    setLabel(data.label);
    onChange(data.value); // Передаем выбранное значение через onChange
  }, []);
  // Закрытие при клике вне компонента
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        // Закрываем список, если клик вне компонента
        setIsDropdownOpen(false);
      }
    };
    // Вешаем обработчик при наведении
    document.addEventListener("mousedown", handleClickOutside);
    // Действия при размонтировании компонента
    return () => {
      // Удаляем обработчик при размонтировании
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);
  // Возвращаем шаблон
  return (
    <div className="my-select__wrap" ref={selectRef}>
      <div className="my-select__inside-wrap" onClick={toggleDropdown}>
        <MyInput
          title={title}
          select
          value={label}
          onChange={onChange}
          placeholder={placeholder}
        />
        <img src={icon} className="my-select__icon" alt="" />
      </div>

      {isDropdownOpen && (
        <div className="my-select__dropdown">
          {options.map((option: IOptionItem) => (
            <div
              key={option.value}
              className="my-select__option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
