// Импорт React и useState
import React, { useState } from "react";
// Импорт хуков useParams, useLocation
import { useParams } from "react-router-dom";
// Импорт компонентов Ant Design
import { Button, Upload, Flex, Empty, Row, Col } from "antd";
import {
  UploadOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { uploadFiles } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
// Импорт типов
import { IUpload } from "../../types/props";
// Импортируем интерфейс файла
import { IFile } from "../../types/base_object";
// Импортируем кнопки для карточки файла
import Controls from "./ItemControls";
// Импортируем кнопки для карточки файла
import RendererItem from "./RenderItem";
import "./index.css"; // Импорт стилей
// Компонент загрузки и отображения файлов
export const UploadImages = React.memo(
  ({ files = [], getData, itemid, single = false, toClose }: IUpload) => {
    // Инициализируем состояние списка файлов
    const [fileList, setFileList] = useState([]);
    // Инициализируем состояние загрузки
    const [loading, setLoading] = useState(false);
    // Получаем ID из параметров URL
    const { id } = useParams();
    // Устанавливаем допустимые типы файлов для загрузки
    const acceptFiles = single
      ? "image/*" // Если единственный файл, то только изображения
      : "video/*,image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf";
    // Функция отправки файлов на сервер
    const isUpload = async (e: any) => {
      // Отменяем попытку скачивания если оно уже идет
      if (loading) return;
      // Меняем состояние кнопки загрузки БД
      setLoading(true);
      // Отменяем стандартное поведение формы
      e.preventDefault();
      // Основная логика
      try {
        // Создаем объект FormData
        const form = new FormData();
        // Добавляем каждый файл в FormData с помощью перебора
        fileList.forEach((file: any) => {
          // Добавляем каждый файл в FormData
          form.append("file", file.originFileObj);
        });
        // Отправляем форму на сервер и получаем ответ
        const { data, error } = await uploadFiles(
          form,
          id || "",
          itemid || "",
          single,
        );
        // Если есть ошибка, показываем уведомление об ошибке
        if (error) return showErrorNotify(error);
        // Если режим одиночной загрузки и есть функция закрытия, закрываем
        if (single && typeof toClose === "function") toClose();
        // Функция получения данных
        else await getData();
        // Показываем уведомление об успешной загрузке
        showSuccessNotify(data);
      } catch ({ message }: any) {
        // console.log(message)
      } finally {
        // Очищаем список файлов
        setFileList([]);
        // Сбрасываем состояние кнопки загрузки
        setLoading(false);
      }
    };
    // Шаблон компонента
    return (
      <Flex gap="small" vertical className={single ? "upload-image" : ""}>
        {!fileList.length && !files.length && (
          <Empty
            style={{
              minWidth: "9.25rem",
              margin: 0,
            }}
          />
        )}

        {!fileList.length && !!files.length && (
          <Row gutter={[8, 8]}>
            {!!files.length &&
              files.reverse().map((file: IFile, i: number) => {
                return (
                  <Col
                    xs={8}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    key={i}
                    style={{ display: "grid", gap: ".5rem" }}
                  >
                    <RendererItem file={file} single={single} />

                    <Controls
                      id={id}
                      itemid={itemid}
                      getData={getData}
                      file={file}
                    />
                  </Col>
                );
              })}
          </Row>
        )}

        <Upload
          maxCount={single ? 1 : 5}
          multiple={single ? false : true}
          fileList={fileList}
          accept={acceptFiles}
          onChange={({ fileList: newFileList }: any) =>
            setFileList(newFileList)
          }
          beforeUpload={() => false}
        >
          <Button
            size="small"
            icon={<UploadOutlined />}
            style={{ width: "100%" }}
          />
        </Upload>

        {!!fileList.length && (
          <Flex vertical gap="small">
            {!single && (
              <Button
                size="small"
                type="primary"
                danger
                onClick={() => setFileList([])}
                style={{ width: "100%" }}
                icon={<CloseOutlined />}
              />
            )}

            <Button
              size="small"
              type="primary"
              onClick={isUpload}
              loading={loading}
              style={{ width: "100%" }}
              icon={<CloudUploadOutlined />}
            />
          </Flex>
        )}
      </Flex>
    );
  },
);
