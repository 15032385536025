import React, { ReactElement } from "react";
import Row from "./Row";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    display: "flex",
    gap: "var(--gap-extraSmall)",
    border: "var(--border)",
    backgroundColor: "var(--gray)",
    borderRadius: "var(--borderRadius)",
    overflow: "hidden",
  },
};
/*
 * Компонент таблицы
 */
export default function ({
  list,
  toInsidePage,
}: {
  list: any;
  toInsidePage: Function;
}): ReactElement {
  // Возвращаем шаблон
  return (
    <div style={{ ...styles.wrap, flexDirection: "column" }}>
      {list.map((item: any, i: number) => {
        return <Row data={item} key={item.id} toInsidePage={toInsidePage} />;
      })}
    </div>
  );
}
