import React, { useState, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Modal, Popconfirm } from "antd";
import { PlusCircleFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import CreateDetail from "../Forms/Create/Detail";
import EditObject from "../Forms/Edit";
import { myFetch } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import { IObject } from "../../types/base_object";
/*
 * Интерфейс входных данных
 */
interface IControls {
  getData: Function;
  isData: IObject;
  id: String;
}
/*
 * Компонент кнопок внутренней страницы
 */
export default function ({ getData, isData, id }: IControls): ReactElement {
  // Статус загрузки
  const [loader, setLoader] = useState(false);
  // Статус модального окна
  const [modal, setModal] = useState(false);
  // Флаг указывающий какое модальное окно открыть
  const [type, setType] = useState("");
  // Уникальный идентификатор модального окна
  const [key, setKey] = useState(Date.now());
  // Объект навигатора
  const navigate = useNavigate();
  // Функция удаления объекта
  const del = async () => {
    // Меняем статус загрузки
    setLoader(true);
    // Основная логика
    try {
      // Делаем запросы получаем ответ сервера
      const { data, error } = await myFetch(`${id}`, {}, "DELETE");
      // Показываем ошибку
      if (error) return showErrorNotify(error);
      // Показываем сообщение
      showSuccessNotify(data);
      // Переадресация на главную страницу
      navigate("/");
    } catch ({ message }: any) {
      // console.log(message);
    } finally {
      // Меняем статус загрузки
      setLoader(false);
    }
  };
  // Закрываем всё
  const toClose = async () => {
    // Закрываем модальное окно
    setModal(false);
    // Добавляем ключ для модального окна
    setKey(Date.now());
  };
  // Возвращаем шаблон
  return (
    <Flex vertical gap="small">
      <Flex gap="small" justify="end">
        <Button
          size="small"
          type="primary"
          onClick={() => {
            // Добавляем тип открываемой модалки
            setType("edit");
            // Добавляем статус для открытия модалки
            setModal(true);
          }}
          icon={<EditFilled />}
        />

        <Button
          size="small"
          type="primary"
          onClick={() => {
            // Добавляем тип открываемой модалки
            setType("create");
            // Добавляем статус для открытия модалки
            setModal(true);
          }}
          style={{ backgroundColor: "var(--green)" }}
          icon={<PlusCircleFilled />}
        />

        <Popconfirm
          title={`удалить?`}
          onConfirm={del}
          okText="да"
          cancelText="нет"
        >
          <Button
            size="small"
            type="primary"
            danger
            loading={loader}
            icon={<DeleteFilled />}
          />
        </Popconfirm>
      </Flex>

      <Modal open={modal} onCancel={toClose}>
        {type === "create" && (
          <CreateDetail
            getData={getData}
            toClose={toClose}
            isData={isData}
            key={key}
          />
        )}

        {type === "edit" && (
          <EditObject
            getData={getData}
            toClose={toClose}
            isData={isData}
            key={key}
          />
        )}
      </Modal>
    </Flex>
  );
}
