import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Flex, Popconfirm, Collapse } from "antd";
import {
  DeleteFilled,
  EditFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import { generateImagePath } from "../../helpers";
import { myFetch } from "../../helpers/my_fetch";
import { UploadImages } from "../UploadImages";
import { IHeaderInside } from "../../types/props";
import { IBreakpoint, IState } from "../../types/store";
import { ImageViewer } from "../ImageViewer";
import MyDivide from "../MyDivide";
import MyDescription from "../MyDescription";
import plugCar from "../../assets/plug_car.png";
import plugObject from "../../assets/plug_object.png";
// Экспорт компонента
export default function ({ isData, getData, id }: IHeaderInside) {
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const { desktop }: IBreakpoint = useSelector(
    (state: IState) => state.breakpoints,
  );
  // Текущая ширина экрана устройства
  const { width } = useSelector((state: IState) => state.windowSize);
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const isDesktop = useMemo(() => width >= desktop, [width, desktop]);
  // Флаг видимости компонента Loader при удалении изображения
  const [loaderDelImage, setLoaderDelImage] = useState(false);
  // Флаг о редактировании изображения
  const [changeImage, setChangeImage] = useState(false);
  // Функция удаления изображения объекта/машины
  const delImage = async () => {
    // Изменение флага видимости компонента Loader
    setLoaderDelImage(true);
    // Основная логика
    try {
      // Отправка запроса для удаления изображения на сервер
      await myFetch(
        `${id}/file/main`,
        { name: isData?.image?.name?.new },
        "DELETE",
      );
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Изменение флага видимости компонента Loader
      setLoaderDelImage(false);
    }
  };
  // Получение пути к изображению для карточки
  const getImagePath = () => {
    const img = isData?.image?.thumb
      ? isData?.image?.thumb
      : isData?.image?.name?.new;
    // Если есть название картинки
    if (img) {
      // Генерация пути к картинке на сервере
      return generateImagePath(img);
    } else {
      // Тип объекта
      const type = isData?.type;
      // Если карточка для объекта
      if (type === "object") {
        // Возвращаем заглушку для объекта
        return plugObject;
      }
      // Если карточка для объекта
      if (type === "car") {
        // Возвращаем заглушку для машины
        return plugCar;
      }
      // Возвращаем пустую строку
      return "";
    }
  };
  // Возвращаем шаблон компонента
  return (
    <Flex gap="small" vertical>
      <Flex gap="small" vertical={!isDesktop}>
        {changeImage ? (
          <Flex vertical gap="small">
            <UploadImages
              single={true}
              getData={getData}
              toClose={async () => {
                setChangeImage(false);

                await getData();
              }}
            />

            <Button
              size="small"
              type="primary"
              danger
              onClick={() => setChangeImage(false)}
              icon={<EyeInvisibleFilled />}
              style={{ width: "100%" }}
            />
          </Flex>
        ) : (
          <Flex vertical gap="small">
            <div
              style={{
                height: !isDesktop ? "348px" : "148px",
                width: !isDesktop ? "100%" : "148px",
              }}
            >
              <ImageViewer
                src={getImagePath()}
                alt={isData?.image?.name?.old}
                single={true}
              />
            </div>

            <Flex gap="small" justify="flex-end">
              <Button
                size="small"
                type="primary"
                onClick={() => setChangeImage(true)}
                icon={<EditFilled />}
              />

              {isData?.image && (
                <Popconfirm
                  title={`удалить?`}
                  onConfirm={delImage}
                  okText="да"
                  cancelText="нет"
                >
                  <Button
                    size="small"
                    type="primary"
                    danger
                    loading={loaderDelImage}
                    icon={<DeleteFilled />}
                  />
                </Popconfirm>
              )}
            </Flex>
          </Flex>
        )}

        {!isDesktop && <MyDivide title="информация" />}

        <MyDescription data={isData} />
      </Flex>

      <Collapse
        items={[
          {
            key: 1,
            label: "файлы",
            children: (
              <UploadImages
                getData={getData}
                files={isData?.files}
                single={false}
              />
            ),
          },
        ]}
      />
    </Flex>
  );
}
