import React from "react";
import { useDispatch } from "react-redux";
//@ts-ignore
import { changeViewMode } from "../../store/reducers/actions.js";
import { Flex, Radio } from "antd";
import {
  BorderlessTableOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../helpers/localstorage";

export default function () {
  const dispatch = useDispatch();

  const handleChangeViewMode = (value: string) => {
    setLocalStorageData("mode", value);
    dispatch(changeViewMode(value));
  };

  return (
    <Flex gap="small">
      <Radio.Group
        onChange={({ target: { value } }) => handleChangeViewMode(value)}
        defaultValue={
          !!getLocalStorageData("mode") ? getLocalStorageData("mode") : "grid"
        }
        size="small"
      >
        <Radio.Button value="grid">
          <BorderlessTableOutlined />
        </Radio.Button>

        <Radio.Button value="list">
          <UnorderedListOutlined />
        </Radio.Button>
      </Radio.Group>
    </Flex>
  );
}
