import React, { useState, useCallback } from "react";
import { fetchBD } from "../../helpers/my_fetch";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
// Интерфейс входных данных
interface IProps {
  title?: string;
  objectId?: String;
  objectName?: String;
}
// Кнопка СКАЧАТЬ
export default function ({ title = "скачать", objectId, objectName }: IProps) {
  // Состояние загрузки
  const [loading, setLoading] = useState(false);
  // Функция запроса данных объекта, если ID изменился
  const download = useCallback(async () => {
    // Отменяем попытку скачивания если оно уже идет
    if (loading) return;
    // Меняем состояние кнопки загрузки БД
    setLoading(true);
    // Основная логика
    try {
      // Получаем данные объекта
      const res = await fetchBD(objectId);
      // Если не положительный статус
      if (res.status !== 200) return;
      // Преобразуем ответ в Blob
      const blob = await res.blob();
      // Создаем URL для Blob
      const url = window.URL.createObjectURL(new Blob([blob]));
      // Создаем элемент ссылки
      const link = document.createElement("a");
      // Устанавливаем URL в качестве href ссылки
      link.href = url;
      // Задаем имя файла для скачивания
      if (objectId && objectName)
        link.setAttribute("download", `${objectName}.zip`);
      // Задаем имя файла для скачивания
      else link.setAttribute("download", "files.zip");
      // Добавляем ссылку в DOM
      document.body.appendChild(link);
      // Имитируем клик по ссылке для скачивания файла
      link.click();
      // Удаляем ссылку из DOM
      document.body.removeChild(link);
      // Освобождаем URL, связанный с Blob
      window.URL.revokeObjectURL(url);
    } catch (message: any) {
      //   console.log(message);
    } finally {
      // Меняем состояние кнопки загрузки БД
      setLoading(false);
    }
  }, []);
  // Возвращаем шаблон компонента
  return (
    <Button
      size="small"
      type="primary"
      title={title}
      loading={loading}
      icon={!loading && <DownloadOutlined />}
      onClick={download}
    />
  );
}
