import React from "react";
import { VideoPlayer } from "../VideoPlayer";
import { PdfViewer } from "../PdfViewer";
import { ImageViewer } from "../ImageViewer";
import { generateImagePath } from "../../helpers";
import { IFile } from "../../types/base_object";
/*
 * Компонент кнопок [скачать, удалить]
 */
export default function ({ file, single }: { file: IFile; single: Boolean }) {
  // Функция для определения, какой элемент рендерить
  const renderFile = (file: any) => {
    const fileName = file?.name?.new;
    const filePath = generateImagePath(file?.thumb ? file?.thumb : fileName);
    if (fileName?.endsWith(".webp")) {
      return (
        <ImageViewer src={filePath} alt={file?.name?.old} single={single} />
      );
    } else if (fileName?.endsWith(".pdf")) {
      return <PdfViewer file={file} />;
    } else if (fileName?.endsWith(".mp4")) {
      return <VideoPlayer file={file} />;
    }
    return null;
  };
  // Возвращаем шаблон компонента
  return (
    <div
      style={{
        borderRadius: "4px",
        overflow: "hidden",
        height: "148px",
        width: "100%",
      }}
    >
      {renderFile(file)}
    </div>
  );
}
