import React, { ReactElement } from "react";
import { Flex } from "antd";
import HeaderMain from "../Header";
import { Outlet } from "react-router-dom";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100vh",
    paddingBottom: "var(--paddingSmall)",
  },
};
/*
 * Компонент глобального layout
 */
export default function (): ReactElement {
  // Возвращаем шаблон
  return (
    <Flex style={styles.wrap} gap="small" vertical>
      <HeaderMain />

      <main className="container">
        <Flex vertical gap="small">
          <Outlet />
        </Flex>
      </main>
    </Flex>
  );
}
