import React, { memo, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import { generateImagePath } from "../../helpers";
import plugCar from "../../assets/plug_car.png";
import plugObject from "../../assets/plug_object.png";
import { IObject } from "../../types/base_object";
/*
 * Интерфейс входных данных карточки объекта
 */
interface IProps {
  object: IObject;
  isGrid: Boolean;
}
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
    padding: "var(--paddingBase)",
    cursor: "pointer",
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
};
/*
 * Компонент карточки объекта/машины
 */
const Card = ({ object, isGrid }: IProps): ReactElement => {
  // State названия карточки
  const [name, setName] = useState<string>(
    `${object?.brand || object?.name || ""}`,
  );
  // State названия изображения карточки
  const [imgName, setImgName] = useState<string>(
    `${object?.image?.name?.new || ""}`,
  );
  // State оригинального названия изображения карточки
  const [imgOldName, setImgOldName] = useState<string>(
    `${object?.image?.name?.old || ""}`,
  );
  // Объект навигации
  const navigate = useNavigate();
  // Функция перехода на внутреннею страницу объекта
  const toInsidePage = () => {
    // Перенаправление
    navigate(`/inside/${object?.id}`);
  };
  // Тип объекта
  const type = object.type;
  // Получение пути к изображению для карточки
  const getImagePath = () => {
    // Если есть название картинки
    if (imgName) {
      // Генерация пути к картинке на сервере
      return generateImagePath(imgName);
    } else {
      // Если карточка для объекта
      if (type === "object") {
        // Возвращаем заглушку для объекта
        return plugObject;
      }
      // Если карточка для объекта
      if (type === "car") {
        // Возвращаем заглушку для машины
        return plugCar;
      }
      // Возвращаем пустую строку
      return "";
    }
  };
  // Возвращаем шаблон
  return (
    <div style={styles.wrap} onClick={toInsidePage} title={name}>
      <Flex gap="small" vertical>
        <img
          style={{
            height: isGrid ? "112px" : "5rem",
            width: isGrid ? "100%" : "5rem",
          }}
          src={getImagePath()}
          loading="lazy"
          className="card__img"
          alt={imgOldName}
        />

        <p style={styles.text}>{name}</p>
      </Flex>
    </div>
  );
};
// Экспортируем и мемоизируем
export default memo(Card);
