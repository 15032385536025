import React, { ReactElement, memo } from "react";
/*
 * Интерфейс входных параметров
 */
interface IProps {
  children: any;
  label: string;
  single?: Boolean;
}
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  label: {
    padding: "var(--paddingSmall)",
    top: "1rem",
    left: "1rem",
    cursor: "default",
    fontSize: "var(--fontSizeExtraSmall)",
    zIndex: 999,
    fontWeight: 600,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "var(--white)",
    borderRadius: "var(--borderRadius)",
  },
};
/*
 * Компонент-обвертка для показа в верхнем левом углу формат файла
 */
const LayoutFormat = React.memo(
  ({ children, label = "", single = false }: IProps): ReactElement => {
    // Возвращаем шаблон
    return (
      <div style={{ ...styles.wrap, position: "relative" }}>
        {!single && (
          <span
            style={{
              ...styles.label,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {label}
          </span>
        )}

        {children}
      </div>
    );
  },
);
/*
 * Экспорт
 */
export default memo(LayoutFormat);
