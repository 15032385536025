import React, { useEffect, useRef, useState } from "react";
import LayoutFormat from "../LayoutFormat";
import { IFile } from "../../types/base_object";
import { MyModal } from "../MyModal";
import { generateImagePath } from "../../helpers";
/*
 * Объект стилей
 */
const styles = {
  video: {
    width: "100%",
    height: "80vh",
    boxShadow: "0 0 1.5rem var(--transparentBlack)",
  },
  img: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
};
/*
 *Компонент для отображения видео-файла
 */
export const VideoPlayer = ({ file }: { file: IFile }) => {
  const [isPath, setPath] = useState<string>("");
  const [isThumb, setThumb] = useState<string>("");
  const [isAlt, setAlt] = useState<string>("");
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(false);
  // Ссылка на элемент видео
  const videoRef = useRef<HTMLVideoElement | null>(null);
  // Действия при инициализации компонента
  useEffect(() => {
    // Генерируем и добавляем путь к файлу
    setPath(generateImagePath(file?.name?.new));
    // Добавляем альтернативное имя файла
    setAlt(file?.name?.old);
    // Генерируем и добавляем путь к превью-изображению
    setThumb(generateImagePath(file?.thumb));
  }, [file]);
  // Функция для остановки видео при закрытии модального окна
  const handleCancel = () => {
    // Если инстанс видео существует
    if (videoRef.current) {
      // Остановить видео
      videoRef.current.pause();
      // Сбросить видео к началу
      videoRef.current.currentTime = 0;
    }
    // Закрываем модальное окно
    changeShow(false);
  };
  // Возвращаем шаблон
  return (
    <>
      <LayoutFormat label="mp4">
        <img
          style={styles.img}
          src={isThumb}
          alt={isAlt}
          onClick={() => changeShow(true)}
        />
      </LayoutFormat>

      {show && (
        <MyModal onCancel={handleCancel}>
          <video
            ref={videoRef}
            controls
            src={isPath}
            preload="auto"
            style={styles.video}
          />
        </MyModal>
      )}
    </>
  );
};
