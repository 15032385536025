import React, { ReactElement } from "react";
import { Flex, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { myFetch } from "./../../helpers/my_fetch";
// Интерфейс входных данных
interface IProps {
  getData: Function;
  parentId: String | undefined;
  objectId: String;
  pageName: String;
  currentIndex: number;
  length: number;
}
// Компонент кнопок перемещения объектов по вертикале
export default function ({
  getData,
  objectId,
  parentId,
  currentIndex,
  length,
}: IProps): ReactElement {
  const changeIndex = async (direction: number) => {
    await myFetch("swap", { direction, objectId, parentId }, "POST");
    await getData();
  };

  // Возвращаем шаблон компонента
  return (
    <Flex gap="small">
      <Button
        size="small"
        title="переместить вверх"
        onClick={() => changeIndex(1)}
        disabled={currentIndex === 0}
      >
        <UpOutlined />
      </Button>

      <Button
        size="small"
        title="переместить вниз"
        onClick={() => changeIndex(0)}
        disabled={currentIndex === length - 1}
      >
        <DownOutlined />
      </Button>
    </Flex>
  );
}
