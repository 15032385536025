import React, { useState, memo } from "react";
import { Flex, Button, Popconfirm, Collapse, Modal } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { myFetch } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import { IDetail } from "../../types/base_object";
import { UploadImages } from "../../components/UploadImages";
import EditDetail from "../Forms/Edit/Detail";
import MoveControls from "../../components/MoveControls";
import DownloadBtn from "../../components/Controls/DownloadBtn";
import MyDescription from "../../components/MyDescription";
// Интерфейс входных данных
export interface IRow {
  getData: Function;
  isData: IDetail;
  id?: String;
  isPage: String;
  index: number;
  length: number;
}
/*
 * Компонент строки
 */
const Row = ({ getData, isData, id, isPage, index, length }: IRow) => {
  // Состояние видимости компонента Loader
  const [loader, setLoader] = useState(false);
  // Состояние видимости модального окна
  const [isModal, setIsModal] = useState(false);
  // Состояние key модального окна
  const [modalKey, setModalKey] = useState(Date.now());
  // Функция удаления
  const del = async () => {
    // Меняем флаг видимости модального окна
    setLoader(true);
    // Основная логика
    try {
      // Метод удаления объекта
      const { data, error } = await myFetch(`${id}/${isData.id}`, {}, "DELETE");
      // Показываем notify ошибки
      if (error) return showErrorNotify(error);
      // Показываем notify
      showSuccessNotify(data);
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Меняем флаг видимости модального окна
      setLoader(false);
    }
  };
  // Функция закрытия модального окна
  const toClose = async () => {
    // Меняем флаг видимости модального окна
    setIsModal(false);
    // Обновляем key модального окна (для очистки данных в модальном окне)
    setModalKey(Date.now());
  };
  // Возвращаем шаблон компонента
  return (
    <Collapse
      items={[
        {
          key: 1,
          label: isData?.name,
          children: (
            <Flex vertical gap="small">
              <Flex gap="small" justify="space-between">
                <Flex gap="small">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => setIsModal(true)}
                    icon={<EditFilled />}
                  />

                  <DownloadBtn
                    title="скачать данные объекта"
                    objectName={isData.name}
                    objectId={isData.id}
                  />

                  <Popconfirm
                    title={`удалить?`}
                    onConfirm={del}
                    okText="да"
                    cancelText="нет"
                  >
                    <Button
                      size="small"
                      type="primary"
                      danger
                      icon={<DeleteFilled />}
                      loading={loader}
                    />
                  </Popconfirm>
                </Flex>

                <MoveControls
                  getData={getData}
                  parentId={id}
                  objectId={isData?.id}
                  pageName={isPage}
                  currentIndex={index}
                  length={length}
                />
              </Flex>

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "данные",
                    children: <MyDescription data={isData} />,
                  },
                ]}
              />

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "файлы",
                    children: (
                      <UploadImages
                        files={isData?.files}
                        itemid={isData?.id ? String(isData?.id) : ""}
                        getData={getData}
                        single={false}
                      />
                    ),
                  },
                ]}
              />

              <Modal open={isModal} onCancel={toClose}>
                <EditDetail
                  getData={getData}
                  toClose={toClose}
                  isData={isData}
                  key={modalKey}
                />
              </Modal>
            </Flex>
          ),
        },
      ]}
    />
  );
};
// Экспорт
export default memo(Row);
