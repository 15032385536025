import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "antd";
import { IState } from "../../types/store";
import Navbar from "../Navbar";
import Logo from "../Logo";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    width: "100%",
    backgroundColor: "var(--white)",
    top: 0,
    zIndex: 1,
  },
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    padding: "var(--paddingBase) 0",
    display: "flex",
    gap: "var(--gap-base)",
  },
};
/*
 * Компонент шапки приложения
 */
export default function () {
  // Получаем ширину экрана для desktop
  const { desktop } = useSelector((state: IState) => state.breakpoints);
  // Текущая ширина экрана клиента
  const { width } = useSelector((state: IState) => state.windowSize);
  // Кэшированное значение ширины экрана клиента
  const isDesktop = useMemo(() => width >= desktop, [width, desktop]);
  // Возвращаем шаблон
  return (
    <div style={{ position: "sticky", ...styles.wrap }}>
      <header
        style={{ ...styles.header, flexDirection: "column" }}
        id="isHeader"
      >
        <div className="container">
          <Flex
            gap="small"
            justify={isDesktop ? "space-between" : ""}
            align={!isDesktop ? "center" : ""}
          >
            <Logo />

            <Navbar />
          </Flex>
        </div>
      </header>
    </div>
  );
}
