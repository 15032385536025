import React, { useState, useEffect, useMemo, ReactElement } from "react";
import { Button, Flex, Form } from "antd";
import { useParams } from "react-router-dom";
import Field from "../components/Field";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IForm } from "../../../types/props";
import { IOption } from "../../../types";
import { PlusOutlined } from "@ant-design/icons";
import MyInput from "../../MyInput";
/*
 * Компонент создания детали для объекта
 */
export default function ({ getData, toClose, isData }: IForm): ReactElement {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [milleage, setMilleage] = useState("");
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);
  // Получаем ID объекта
  const { id } = useParams();
  // Очищаем все поля
  const allClear = () => {
    setName("");
    setDescription("");
    setMilleage("");
    setOptions({});
    setLoading(false);
  };
  // Действия при инициализации компонента
  useEffect(() => {
    // Очищаем поля
    allClear();
  }, []);
  // Добавляем опцию для детали
  const addField = (fieldName: string): void => {
    setOptions((prevFields) => ({
      ...prevFields,
      [fieldName]: "",
    }));
  };
  // Функция изменения опции детали
  const changeField = (name: string, value: Object): void => {
    setOptions((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };
  // Функция удаления опции для детали
  const deleteField = (nameToDelete: string): void => {
    setOptions((prev) => {
      const updatedOptions: { [key: string]: IOption } = { ...prev };
      delete updatedOptions[nameToDelete];
      // Возвращаем массив
      return updatedOptions;
    });
  };
  // Функция создания детали
  const isCreate = async (): Promise<void> => {
    setLoading(true);
    // Основная логика
    try {
      // Выясняем тип объекта
      const type = isData?.type;
      // Полезная нагрузка
      let payload: any = {
        name,
        description,
        type,
        objectId: id,
        options,
      };
      // Проверяем условие
      if (type === "car") {
        payload.milleage = milleage;
      }
      // Делаем запрос на сервер
      const { data, error } = await myFetch(`detail`, payload, "POST");
      // Выводим уведомление об ошибке
      if (error) return showErrorNotify(error);
      // Выводим успешное уведомление
      showSuccessNotify(data);
      // Запрос данных
      await getData();
      // Закрываем модальное окно
      toClose(false);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Очищаем поля
      allClear();
    }
  };
  // Определение активности кнопки для формы
  const isDisabled: boolean = useMemo(() => {
    // Возвращаем статус
    return !name;
  }, [name]);
  // Возвращаем шаблон
  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isCreate}>
      <Flex gap="small" vertical>
        <MyInput
          title={"Название"}
          value={name}
          onChange={setName}
          placeholder="Введите название"
        />

        <MyInput
          title={"Описание"}
          value={description}
          onChange={setDescription}
          placeholder="Введите описание"
        />

        {isData?.type === "car" && (
          <MyInput
            title={"Пробег"}
            value={milleage}
            onChange={setMilleage}
            placeholder="Введите пробег"
            milleage
          />
        )}

        {Object.keys(options).map((fieldName) => (
          <Field
            key={fieldName}
            fieldName={fieldName}
            onFieldChange={changeField}
            onDeleteField={deleteField}
            //@ts-ignore
            value={options[fieldName]}
          />
        ))}

        <Button
          size="small"
          type="primary"
          style={{ width: "100%", backgroundColor: "var(--green)" }}
          onClick={() => addField(`${Date.now()}`)}
          icon={<PlusOutlined />}
        >
          поле
        </Button>

        <Button
          size="small"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isDisabled}
        >
          создать
        </Button>
      </Flex>
    </Form>
  );
}
