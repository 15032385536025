import React, { useState, useEffect } from "react";
import { Button, Flex } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { IOption } from "../../../types";
import MyInput from "../../MyInput";
/*
 * Интерфейс входных данных
 */
interface IProps {
  fieldName: string;
  onFieldChange: Function;
  onDeleteField: Function;
  value: IOption;
}
/*
 * Компонент опции для детали объекта
 */
export default function ({
  fieldName,
  onFieldChange,
  onDeleteField,
  value,
}: IProps) {
  // State названия
  const [name, setName] = useState("");
  // State стоимость
  const [cost, setCost] = useState("");
  // Действия при изменении value
  useEffect(() => {
    if (value.name) setName(value.name);
    if (value.cost) setCost(value.cost);
  }, [value]);
  // Действия при изменении name и cost
  useEffect(() => {
    onFieldChange(fieldName, { name, cost });
  }, [name, cost]);
  // Возвращаем шаблон
  return (
    <Flex gap="small" align="center">
      <MyInput
        title={"Название"}
        value={name}
        onChange={setName}
        placeholder="Введите название"
      />

      <MyInput
        title={"Стоимость"}
        value={cost}
        onChange={setCost}
        placeholder="Введите стоимость"
        cost
      />

      <Button
        size="small"
        type="primary"
        danger
        icon={<MinusOutlined />}
        style={{
          minWidth: "1.9375rem",
          minHeight: "1.9375rem",
          borderRadius: "0.375rem",
        }}
        onClick={() => onDeleteField(fieldName)}
      />
    </Flex>
  );
}
