import React from "react";
import { Spin } from "antd";
/*
 * Объект стилей
 */
const styles = {
  loading: {
    height: "5rem",
    display: "flex",
    justifyContent: "center",
    gap: ".5rem",
  },
};
/*
 * Компанент Loader
 */
export default function () {
  return (
    <div style={{ ...styles.loading, flexDirection: "column" }}>
      <Spin tip="Загрузка..." size="large">
        <div className="content" />
      </Spin>
    </div>
  );
}
