import React, { ReactElement, ChangeEvent, useState } from "react";
import {
  sanitize,
  maskCarNumber,
  maskPhoneNumber,
  maskCost,
} from "../../helpers";
import MyDivide from "../MyDivide";
import "./index.css"; // Импорт стилей
/*
 * Интерфейс входных данных
 */
interface IProps {
  title?: string;
  select?: boolean;
  carNumber?: boolean;
  phoneNumber?: boolean;
  cost?: boolean;
  milleage?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}
/*
 * Кастомный компонент select
 */
export default function ({
  title = "",
  select = false,
  carNumber = false,
  phoneNumber = false,
  cost = false,
  milleage = false,
  placeholder = "",
  value,
  onChange,
}: IProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false);
  // Обработчик изменений в поле ввода
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Получаем значение
    const val = sanitize(e.target.value);
    // Проверяем условия
    if (carNumber) {
      onChange(maskCarNumber(val));
    } else if (phoneNumber) {
      onChange(maskPhoneNumber(val));
    } else if (cost || milleage) {
      onChange(maskCost(val));
    } else {
      onChange(val);
    }
  };
  // Возвращаем шаблон
  return (
    <div
      className="my-input__wrap"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {title && <MyDivide title={title} left hovered={isHovered} />}

      <input
        className={"my-input__item"}
        style={{
          cursor: select ? "pointer" : "",
          borderColor: isHovered ? "var(--primary)" : "var(--gray)",
        }}
        readOnly={select}
        value={value}
        onChange={handleChange}
        placeholder={placeholder || title}
      />
    </div>
  );
}
